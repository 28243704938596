import React from "react"
import HomeSubtitle from "../home-subtitle/HomeSubtitle"
import { transformText } from "../../utils/text.util"
import "./homeBenefits.scss"

const HomeBenefits = props => {
  const { category, title, description, items } = props

  return (
    <section className="homeBenefits">
      <article className="homeBenefits_card homeBenefits_card__main">
        <HomeSubtitle category={category}>{title?.richText[0]}</HomeSubtitle>
        <p className="benefitCardMessage">
          {transformText(description?.richText[0], "benefitCardMessage_strong")}
        </p>
      </article>
      {items?.map((benefit, benefitIndex) => (
        <article
          className="homeBenefits_card"
          key={`homeBenefits_card${benefitIndex}`}
        >
          <figure
            className="benefitCardHeader"
            style={{ backgroundImage: `url(${benefit?.benefit_image?.url})` }}
          />
          <div className="benefitCardBody">
            <p className="benefitCardBody_description">
              {benefit?.benefit_detail?.text}
            </p>
          </div>
        </article>
      ))}
    </section>
  )
}

export default HomeBenefits
